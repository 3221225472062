import React, { useEffect, useRef, useState } from 'react'
import { Modal, Form, Row, Col, Select, Alert, Button, message  } from 'antd'
import Bread from '@/components/Bread/index';
import $$ from 'cmn-utils';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};
const { Option } = Select;

export default function ({ bread=[], refs }) {

  refs.current = {
    show: (param) => {
      // console.log('invite',param)
      setVisible(true);
      setLink('');
      form.resetFields();
      if(param.teamCode){
        setTeamCode(param.teamCode)
      }
      if(param.projectCodes){
        setProjectCodes(param.projectCodes)
      }
    },
    close: ()=>{
      setVisible(false)
    }
  }

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [teamCode, setTeamCode] = useState('');
  const [projectCodes, setProjectCodes] = useState('');
  const [link, setLink] = useState('');

  const handleCancel = ()=>{
    setVisible(false)
  }

  const [form] = Form.useForm();
  
  const handleReset = () => {
    setVisible(true);
      setLink('');
  }
  const handleSubmit = () => {
    setConfirmLoading(true);
    form
    .validateFields()
    .then(values => {
      // console.log('form data:',values);
      save(values);
    })
    .catch(info => {
      setConfirmLoading(false);
    });
  };

  const save = async ({userRole})=>{
    let data = {teamCode,userRole};
    if(projectCodes) data.projectCodes = projectCodes;
    const res = await $$.post('/platform/api/v1/teams/generate-invite',data);
    setConfirmLoading(false);
    console.log('res link',res)
    if(res.success && res.data){
      message.success('成功生成邀请链接');
      setLink(res.data);
    }
  }

  return (
    <Modal
        title = {<Bread bread={[...bread, '邀请队员']} />}
        visible={visible}
        onOk={handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText={'确定'}
        cancelText={'取消'}
        width={800}
        footer={[
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" loading={confirmLoading} onClick={link?handleReset:handleSubmit}>
            {link?'重新生成':'确定'}
          </Button>,
        ]}
      >
        {!link &&
        <Form
            {...layout}
            form={form}
            name="form_in_modal"
            initialValues={{ modifier: 'public' }}
          >
          <Row>
            <Col span={24}>
              <Form.Item 
                name="userRole" 
                label="成员角色"
                rules={[{ required: true, message: '请选择成员角色' }]}
                >
                <Select
                  placeholder="请选择成员角色"
                  allowClear
                >
                  <Option value={'twin_admin'}>管理者</Option>
                  <Option value={'twin_edit'}>编辑者</Option>
                  <Option value={'twin_view'}>查看者</Option>
                </Select>
              </Form.Item>
            </Col>     
          </Row>
        </Form>
        }
        {link &&
        <>
        请复制下面邀请链接发给被邀请的成员
        <Alert style={{marginTop:20}} message={link?link:'error'} type="info" />
        </>
        }
    </Modal>
  );
}
