import React from 'react';
import { connect, router, routerRedux } from 'dva';
import { Layout } from 'antd';
const { Switch } = router;

@connect(({ global }) => ({ global }))
export default class Routers extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
   
    const { routerData, location, global } = this.props;

    const { childRoutes } = routerData;
  
    return (
      <Switch location={location}>{childRoutes}</Switch>
    );
  }
}
