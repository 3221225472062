import {dynamicWrapper, createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';

import Index from './routers/Index';
import My from './routers/My';
import Pool from './routers/Pool';

const routesConfig = (app) => ({
  path: '/project',
  title: '项目管理',
  component: Routers,
  exact: false,
  indexRoute: '/project/pool',
  childRoutes: [
    Index(app),
    My(app),
    Pool(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
