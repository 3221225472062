import React, { useRef, useState } from 'react'
import { Modal, Table, Spin, Alert, Button, Popconfirm, message  } from 'antd'
import Bread from '@/components/Bread/index';
import $$ from 'cmn-utils';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

export default function ({ bread, refs }) {

  refs.current = {
    show: (param) => {
      setVisible(true)
      loadData(param.teamCode);
      setTeamCode(param.teamCode)
    },
    close: ()=>{
      setVisible(false)
    }
  }

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teamCode, setTeamCode] = useState(0);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: 10,
    total: 0,
  });


  const loadData = async (code, limit = pagination)=>{
    const res = await $$.get('/platform/api/v1/teams/'+code+'/users',{
      joinStatus:'1,2,3',
      pageNum: limit.pageNum,
      pageSize: limit.pageSize
    });
    if(res.success){
      setList(res.data.records);
      setPagination({
        ...limit,
        total: res.data.total
      })
    }
  }

  const handler = {
    check:async (param)=>{
      console.log('check',param)
      const res = await $$.post('/platform/api/v1/teams/check',{
        requestId: param.requestId,
        status: param.status
      });
      if(res.success){
        message.success('操作成功');
        loadData(teamCode);
      }
    },
    filter:{
      status:(value)=>{
        let text = '';
        switch(value){
          case '1':
            text = '申请中';
            break;
          case '2':
            text = '已加入';
            break;
          case '3':
            text = '已拒绝';
            break;
        }
        return text;
      }
    },
    pagination: {
      change:(pager)=>{
        let limit = {
          ...pagination,
          pageNum: pager.current
        }
        loadData(teamCode, limit)
      }
    }
  }


  let columns = [
    {
        title: '姓名',
        dataIndex: 'userName',
        key: 'userName',
    },
    {
      title: '创建时间',
      dataIndex: 'joinTime',
      key: 'joinTime',
    },
    {
        title: '状态',
        dataIndex: 'joinStatus',
        key: 'joinStatus',
        render: (text) => (<>{handler.filter.status(text)}</>),
    },
    {
        title: '操作',
        render: (text, record) => (
            <>
            {record.joinStatus == '1' &&
            <>
              <Popconfirm
                title="确定通过此申请?"
                onConfirm={()=>handler.check({...record, status:2})}
                okText="确定"
                cancelText="取消"
              >
                <Button size="small" type="link">通过</Button>
              </Popconfirm>
              <Popconfirm
                title="确定驳回此申请?"
                onConfirm={()=>handler.check({...record, status:3})}
                okText="确定"
                cancelText="取消"
              >
                <Button size="small" type="link">驳回</Button>
              </Popconfirm>
            </>
            }
            </>
        ),
    }
  ];

  return (
    <>
    <Modal
        title = {<Bread bread={[...bread, '团队成员']} />}
        visible={visible}
        onCancel={refs.current.close}
        footer={false}
        width={800}
      >
        <Spin spinning={loading}>
          <Table
            style={{
              marginTop:15
            }}
            rowKey="userId"
            columns={columns}
            dataSource={list}
            bordered
            pagination={pagination}
            onChange={handler.pagination.change}
          />
        </Spin>
    </Modal>
    </>
  );
}
