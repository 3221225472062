import { routerRedux } from 'dva';
import React from 'react';
import PageLoading from 'components/Loading/PageLoading';
import { normal } from 'components/Notification';
import store from 'cmn-utils/lib/store';

// 系统通知, 定义使用什么风格的通知，normal或antdNotice
const notice = normal;
const apiUrl = process.env.REACT_APP_API_URL;
// console.log('api url',apiUrl)
/**
 * 应用配置 如请求格式，反回格式，异常处理方式，分页格式等
 */
export default {
  /**
   * HTML的title模板
   */
  htmlTitle: 'IDT - {title}',
  
  /**
   * 系统通知
   */
  notice,

  // 异步请求配置
  request: {

    prefix: apiUrl,

    // 每次请求头部都会带着这些参数
    withHeaders: () => ({
      Authorization: store.getStore("token") ? ("Bearer " + store.getStore("token")) : '',
      'X-System-Code': 'system',
      'X-User-Type': 'user'
    }),

    /**
     uydsaz ·* 因为modelEnhance需要知道服务器反回的数据，
     * 什么样的是成功，什么样的是失败，如
     * {status: true, data: ...} // 代表成功
     * {status: false, message: ...} // 代表失败
     * 实际中应该通过服务端反回的response中的
     * 成功失败标识来进行区分
     */
    afterResponse: response => {
      if(typeof response.success !== 'undefined'){
        response.status = response.success;
      }
      if(typeof response.success !== 'undefined'){
        response.message = response.msg;
      }

      const { success, message } = response;

      if (!success) {
        notice.error(message);
      }
      
      return response;
    },
    errorHandle: err => {
      // console.log('err',JSON.stringify(err),err.code);
      // 请求错误全局拦截    
      if(err.code === 401){
        // routerRedux.replace('/sign/login');
        window.location.href = '/#/sign/login';
      }else{
        notice.error(err.text || err.message);
      }
    }
  },

  // 全局异常
  exception: {
    global: (err, dispatch) => {
      // console.log('error',JSON.stringify(err))
      const errName = err.name;
      // RequestError为拦截请求异常
      if (errName === 'RequestError') {      
        if(err.code === 401){
          routerRedux.replace('/sign/login');
        }else{
          notice.error(err.text || err.message);
        }
      } else {
        console.error(err || err.message);
      }
    },
  },

  // 分页助手
  pageHelper: {
    // 格式化要发送到后端的数据
    requestFormat: pageInfo => {
      const { pageNum, pageSize, filters, sorts } = pageInfo;
      return {
        pageNum: pageNum,
        pageSize: pageSize,
        sorts: sorts,
        filters: filters
      };
      // return {
      //   currentPage: pageNum,
      //   showCount: pageSize,
      //   sortMap: sorts,
      //   paramMap: filters
      // };
    },

    // 格式化从后端反回的数据
    responseFormat: resp => {
      // console.log('request:',resp);
      // return resp.data;
      const {
        current,
        size,
        total,
        records,
        totalPage
      } = resp.data;
      return {
        pageNum: current,
        pageSize: size,
        total: total,
        totalPages: totalPage,
        list: records
      };
    }
  },

  // 路由加载效果
  router: {
    loading: <PageLoading loading />
  },

  /**
   * 模拟数据时包装反回数据
   * 因为，后端反回数据时一般都会在外边包装一层状态信息
   * 如成功时：
   * {
   *   status: true,
   *   data: responseData
   * }
   * 或出错时：
   * {
   *   status: false,
   *   code: 500,
   *   message: '用户名或密码错误'
   * }
   * 这里就是配置这两个函数，为了我们模拟数据时可以少写几行代码的 orz...
   */
  mock: {
    toSuccess: response => ({
      status: true,
      success: true,
      data: response
    }),

    toError: message => ({
      status: false,
      success: false,
      message: message,
      msg: message
    })
  }
};
