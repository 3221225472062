import {dynamicWrapper, createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import Module from './routers/Module';
import Menu from './routers/Menu';
import Group from './routers/Group';
import Dimension from './routers/Dimension';
import Resource from './routers/Resource';
import Dict from './routers/Dict';

const routesConfig = (app) => ({
  path: '/system',
  title: '系统管理',
  component: Routers,
  exact: false,
  indexRoute: '/system/menu',
  childRoutes: [
    Module(app),
    Menu(app),
    Group(app),
    Dimension(app),
    Resource(app),
    Dict(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
