import React, { PureComponent } from 'react';
import Icon from '../Icon';
import { Popover, Badge, Avatar } from 'antd';
import { router } from 'dva';
import cx from 'classnames';
import './style/index.less';
import logoImg from 'assets/images/logo.png';
import SearchBox from './SearchBox';
const { Link } = router;

import $$ from 'cmn-utils';

/**
 * 其本本局头部区域
 */
class NavBar extends PureComponent {
  state = {
    openSearchBox: false
  };

  static defaultProps = {
    fixed: true,
    theme: '' //'bg-dark',
  };

  toggleFullScreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  onCloseSearchBox = () => {
    this.setState({
      openSearchBox: false
    });
  };

  onOpenSearchBox = () => {
    this.setState({
      openSearchBox: true
    });
  };

  onLogout = async (url) => {
    const res = await $$.post('/passport/api/v1/logout')
    if(res.success){
      window.location.href = url;
    }
  }

  render() {
    const { openSearchBox } = this.state;
    const {
      fixed,
      theme,
      onCollapseLeftSide,
      collapsed,
      onExpandTopBar,
      toggleSidebarHeader,
      user,
      isMobile
    } = this.props;

    let logoutLink = '/#/sign/login';
    // if(user.userType == 'user') logoutLink = '/sign/admin';
    const classnames = cx('navbar', {
      'navbar-fixed-top': !!fixed,
      'navbar-sm': isMobile ? true : collapsed,
      ['bg-' + theme]: !!theme
    });

    const UserDropDown = props => (
      <ul className="dropdown-menu list-group dropdown-persist">
        {/* <li className="list-group-item">
          <a className="animated animated-short fadeInUp">
            <Icon type="mail" /> 信息
            <Badge count={5} className="label" />
          </a>
        </li>
        <li className="list-group-item">
          <a className="animated animated-short fadeInUp">
            <Icon type="users" /> 好友
            <Badge count={6} className="label" />
          </a>
        </li>
        <li className="list-group-item">
          <a className="animated animated-short fadeInUp">
            <Icon type="gear" /> 帐户设置
          </a>
        </li>
        <li className="list-group-item">
          <a className="animated animated-short fadeInUp">
            <Icon type="ring" /> 通知
          </a>
        </li> */}
        <li onClick={()=>this.onLogout(logoutLink)} className="list-group-item dropdown-footer">
          <a>
            <Icon type="poweroff" /> 退出
          </a>
        </li>
      </ul>
    );
    
    return (
      <header className={classnames}>
        <div className="navbar-branding">
          <Link className="navbar-brand" to="/">
            <img src={logoImg} alt="logo" />
            <b></b>
            数字孪生建模工具
          </Link>
          <span className="toggle_sidemenu_l" onClick={onCollapseLeftSide}>
            <Icon type="lines" />
          </span>
        </div>
        <ul className="nav navbar-nav navbar-left clearfix">
          {/* {collapsed || isMobile ? null : (
            <li>
              <a className="sidebar-menu-toggle" onClick={toggleSidebarHeader}>
                <Icon type="users" />
              </a>
            </li>
          )} */}
          {/* <li>
            <a onClick={onExpandTopBar}>
              <Icon type="wand" />
            </a>
          </li> */}
          {isMobile ? (
            <li className="mini-search" onClick={this.onOpenSearchBox}>
              <a>
                <Icon type="search" antd />
              </a>
            </li>
          ) : (
            <li onClick={this.toggleFullScreen}>
              <a className="request-fullscreen">
                <Icon type="screen-full" />
              </a>
            </li>
          )}
        </ul>
        
        <ul className="nav navbar-nav navbar-right clearfix">
          {/* <li className="dropdown">
            <Popover
              placement="bottomRight"
              title={'通知'}
              overlayClassName={cx('navbar-popup', { [theme]: !!theme })}
              content={''}
              trigger="click"
            >
              <a className="dropdown-toggle">
                <Icon type="radio-tower" />
              </a>
            </Popover>
          </li> */}
          <li className="dropdown">
            <Popover
              placement="bottomRight"
              title={`WELCOME ${user.nickname}`}
              overlayClassName={cx('navbar-popup', { [theme]: !!theme })}
              content={<UserDropDown />}
              trigger="click"
            >
              <a className="dropdown-toggle">
                <Badge dot>
                  <Avatar src={require('assets/images/avatar.jpg')}>
                    {user.nickname}
                  </Avatar>
                </Badge>
              </a>
            </Popover>
          </li>
        </ul>
        <SearchBox visible={openSearchBox} onClose={this.onCloseSearchBox} />
      </header>
    );
  }
}

export default NavBar;
