/**
 * 模拟project数据
 */
export default ({fetchMock, delay, mock, toSuccess, toError}) => {
  return {
    // 表格带分页
    '/api/project/getList': (options) => {
      const body = JSON.parse(options.body);
      const currentPage = body.currentPage;
      const idbase = (currentPage - 1) * 10 + 1;
      const paramMap = body.paramMap;
      const deptName = paramMap.deptName;

      if (deptName == 'abcd') {
        return toSuccess(mock({
          'currentPage': currentPage,
          'showCount': body.showCount,
          'totalResult': 0,
          'totalPage': 0,
          dataList: [],
        }), 400)
      }

      return toSuccess(mock({
        'currentPage': currentPage,
        'showCount': body.showCount,
        'totalResult': 100,
        'totalPage': 10,
        [`dataList|${body.showCount}`]: [{
          'id|+1': idbase,
          'deptName': deptName ? deptName : '@cword(3, 5)',      
          'distributionNetwork|1': ['0', '1'],
          'address': '@county()',
          'type': '@cword(3)',  
          'planBeginTime': '@date',
          'planEndTime': '@date',
          'workEmployee|1-3': [{
            'key|+1': 1,
            'title': '@cname',
          }],
          'content': '@csentence',
        }],
      }), 400)
    },
    '/api/project/bathDelete': (options) => toSuccess({options}, 400),
    '/api/project/getWorkEmployee': (options) => mock({
      'status': true,
      'data|10': [{
        'key|+1': 1,
        'title': '@cname',
      }]
    }),
    '/api/project/save': (options) => toSuccess({options}, 800),
    '/api/project/getWorkspace': (options) => {
      return toSuccess([{
        title: '安徽红四方',
        key: '0-0',
        children: [{
          title: '复合肥#6线',
          key: '0-0-0'
        }, {
          title: '销售总监',
          key: '0-0-1',
          children: [
            { title: '订单管理部', key: '0-0-1-0' },
            { title: '吹水培训中心', key: '0-0-1-1' },
            { title: '分公司', key: '0-0-1-2' },
          ],
        }],
      }, {
        title: 'test',
        key: '0-1',
        children: [
          { title: 'test1', key: '0-1-0-0' },
          { title: 'test2', key: '0-1-0-1' },
        ],
      }, {
        title: 'SENDER001',
        key: '0-2',
      }], 400)
    },
  }
}