/**
 * 模拟请求数据
 * @param {FetchMock} fetchMock 当现有条件不满足时，可以使用fetchMock来进行扩展
 * @param {function} delay 增加延迟时间 ms 例: delay(mockData) 或 delay(mockData, 200)
 * @param {function} mock 使用mock生成数据，例:

   mock({
     'string|1-10': '★' // 生成最少1颗，最多10颗星字符
   })

   // {'string': '★★★★★★'} 

  更多用法参考 http://mockjs.com/examples.html
 */
export default ({fetchMock, delay, mock, toSuccess, toError}) => {
  // 如果现有扩展不满足需求，可以直接使用fetchMock方法
  // fetchMock.mock(/httpbin.org\/post/, {/* response */}, {/* options */});

  return {
    '/api/user/login': (options) => {
      if (options.body) {
        const user = JSON.parse(options.body);
        if (user.username === '18782272725' && user.password === '123456') {
          return toSuccess(mock({
            'userName': 'xyij@foxmail.com',     // 账号
            'name': '@cname',                   // 中文名称
            'age|1-100': 100,                   // 100以内随机整数
            'birthday': '@date("yyyy-MM-dd")',  // 日期
            'city': '@city(true)',              // 中国城市
            'phone': /^1[385][1-9]\d{8}/,       // 手机号
            'access_token': '@guid'                    // token
          }), 400);
        } else {
          return toError('用户名或密码错误');
        }
      } else {
        return toError('请输入用户名和密码');
      }
    },
    '/api/user/register': options => toSuccess(),
    'http://69.230.212.217:18888/user/menu': options => toSuccess([
      {
        name: '首页',
        icon: 'HomeOutlined',
        path: '/home',
      },
      // {
      //   name: '工厂',
      //   icon: 'DashboardOutlined',
      //   path: '/factory',
      // },
      {
        name: '项目管理',
        icon: 'DashboardOutlined',
        path: '/project',
      },
      
      {
        name: '组件',
        icon: 'DesktopOutlined',
        path: '/component',
        children: [
          {
            name: '工具条',
            path: '/toolbar',
          },
          {
            name: 'BaseComponent',
            path: '/baseComponent',
          },
          {
            name: 'Columns',
            path: '/column',
          },
          {
            name: '搜索条',
            path: '/searchBar',
          },
          {
            name: '数据表格',
            path: '/datatable',
          },
          {
            name: '表单',
            path: '/form',
          },
          {
            name: '穿梭树',
            path: '/transferTree',
          },
          {
            name: '图表',
            path: '/charts',
            children: [
              {
                name: 'ECharts',
                path: '/charts/ec',
              },
              {
                name: 'G2',
                path: '/charts/g2',
              },
            ]
          },
          {
            name: '打印',
            path: '/print',
          },
          {
            name: 'Banner 管理',
            path: '/banner',
          },
        ],
      },
      {
        name: 'UI元素',
        icon: 'ShareAltOutlined',
        path: '/ui',
        children: [
          {
            name: '按钮',
            path: '/button',
          },
          {
            name: '图片',
            path: '/image',
          },
          {
            name: '消息',
            path: '/alerts',
          },
          {
            name: '动画',
            path: '/animations',
          },
          {
            name: '图标',
            path: '/icons',
          },
          {
            name: '富文本',
            path: '/editor',
          },
          {
            name: '模态窗',
            path: '/modal',
          },
          {
            name: '遮罩',
            path: '/mask',
          },
        ],
      },
      {
        name: '页面',
        icon: 'BookOutlined',
        path: '/page',
        children: [
          {
            name: '登录页',
            path: '/sign/login',
          },
          {
            name: '注册页',
            path: '/sign/register',
          },
          {
            name: '锁屏',
            path: '/lock',
          },
          {
            name: '画廊',
            path: '/gallery',
          },
          {
            name: '空白页',
            path: '/blank',
          },
          {
            name: '结果页',
            path: '/result',
          },
          {
            name: 'Coming Soon',
            path: '/coming',
          },
          {
            name: '403',
            path: '/403',
          },
          {
            name: '404',
            path: '/404',
          },
          {
            name: '500',
            path: '/500',
          },
          {
            name: '多级路由',
            path: '/level-route/:sub?',
          },
        ],
      },
      {
        name: '通用场景',
        icon: 'BulbOutlined',
        path: '/business',
        children: [
          {
            name: 'CRUD',
            path: '/crud/:detail?',
          }
        ],
      },
    ], 400),

    // 'http://69.230.212.217:18888/platform/api/v1/current-user-info': options => toSuccess({
    //   permissions: [],
    //   userInfo:{
    //     userId:"413682892616507114",
    //     userType: "platform",
    //     userTypeText: "平台用户",
    //     employeeName: "",
    //     status:'1',
    //     statusText: "待审核",
    //     userIdentity: null
    //   },
    //   menus: [
    //   {
    //     name: '首页',
    //     icon: 'HomeOutlined',
    //     path: '/home',
    //   },
    //   // {
    //   //   name: '工厂',
    //   //   icon: 'DashboardOutlined',
    //   //   path: '/factory',
    //   // },
    //   {
    //     name: '项目管理',
    //     icon: 'DashboardOutlined',
    //     path: '/project',
    //     children: [
    //       {
    //         name: '项目池',
    //         path: '/project/pool',
    //       },
    //       {
    //         name: '我的项目',
    //         path: '/project/index',
    //       },
    //     ]
    //   },
      
    //   {
    //     name: '组件',
    //     icon: 'DesktopOutlined',
    //     path: '/component',
    //     children: [
    //       {
    //         name: '工具条',
    //         path: '/toolbar',
    //       },
    //       {
    //         name: 'BaseComponent',
    //         path: '/baseComponent',
    //       },
    //       {
    //         name: 'Columns',
    //         path: '/column',
    //       },
    //       {
    //         name: '搜索条',
    //         path: '/searchBar',
    //       },
    //       {
    //         name: '数据表格',
    //         path: '/datatable',
    //       },
    //       {
    //         name: '表单',
    //         path: '/form',
    //       },
    //       {
    //         name: '穿梭树',
    //         path: '/transferTree',
    //       },
    //       {
    //         name: '图表',
    //         path: '/charts',
    //         children: [
    //           {
    //             name: 'ECharts',
    //             path: '/charts/ec',
    //           },
    //           {
    //             name: 'G2',
    //             path: '/charts/g2',
    //           },
    //         ]
    //       },
    //       {
    //         name: '打印',
    //         path: '/print',
    //       },
    //       {
    //         name: 'Banner 管理',
    //         path: '/banner',
    //       },
    //     ],
    //   },
    //   {
    //     name: 'UI元素',
    //     icon: 'ShareAltOutlined',
    //     path: '/ui',
    //     children: [
    //       {
    //         name: '按钮',
    //         path: '/button',
    //       },
    //       {
    //         name: '图片',
    //         path: '/image',
    //       },
    //       {
    //         name: '消息',
    //         path: '/alerts',
    //       },
    //       {
    //         name: '动画',
    //         path: '/animations',
    //       },
    //       {
    //         name: '图标',
    //         path: '/icons',
    //       },
    //       {
    //         name: '富文本',
    //         path: '/editor',
    //       },
    //       {
    //         name: '模态窗',
    //         path: '/modal',
    //       },
    //       {
    //         name: '遮罩',
    //         path: '/mask',
    //       },
    //     ],
    //   },
    //   {
    //     name: '页面',
    //     icon: 'BookOutlined',
    //     path: '/page',
    //     children: [
    //       {
    //         name: '登录页',
    //         path: '/sign/login',
    //       },
    //       {
    //         name: '注册页',
    //         path: '/sign/register',
    //       },
    //       {
    //         name: '锁屏',
    //         path: '/lock',
    //       },
    //       {
    //         name: '画廊',
    //         path: '/gallery',
    //       },
    //       {
    //         name: '空白页',
    //         path: '/blank',
    //       },
    //       {
    //         name: '结果页',
    //         path: '/result',
    //       },
    //       {
    //         name: 'Coming Soon',
    //         path: '/coming',
    //       },
    //       {
    //         name: '403',
    //         path: '/403',
    //       },
    //       {
    //         name: '404',
    //         path: '/404',
    //       },
    //       {
    //         name: '500',
    //         path: '/500',
    //       },
    //       {
    //         name: '多级路由',
    //         path: '/level-route/:sub?',
    //       },
    //     ],
    //   },
    //   {
    //     name: '通用场景',
    //     icon: 'BulbOutlined',
    //     path: '/business',
    //     children: [
    //       {
    //         name: 'CRUD',
    //         path: '/crud/:detail?',
    //       }
    //     ],
    //   },
    //   ],
    // },400),
  } 
}