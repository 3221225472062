import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, Input} from 'antd'
import Bread from '@/components/Bread/index';
import $$ from 'cmn-utils';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

export default function ({ bread, refs, handleOk }) {

  refs.current = {
    add: () => {
      setVisible(true)
      form.resetFields();
    },
    edit: (param) => {
      console.log('param',param)
      setVisible(true)
      form.resetFields();
      form.setFieldsValue(param);
      setId(param.teamCode);
    },
    close: ()=>{
      setVisible(false)
    }
  }

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [id, setId] = useState(0);

  useEffect(() => {
    
  },[])

  const handleCancel = ()=>{
    setVisible(false)
  }

  const [form] = Form.useForm();

  const handleSubmit = () => {
    setConfirmLoading(true);

    form
    .validateFields()
    .then(values => {
      console.log('form data:',values);
      save(values);
    })
    .catch(info => {
      setConfirmLoading(false);
    });
  };

  const save = async (data)=>{
    let url = '/platform/api/v1/teams';
    let method = 'POST';
    if(id){
      url = '/platform/api/v1/teams/change-name'
      data.teamCode = id;
      method = 'PUT';
    }

    const res = await $$.send(url, {
      method: method,
      data: data
    });

    setConfirmLoading(false);
    console.log('project save:',res);
    if(res.success){
      handleOk();
    }
  }


  return (
    <Modal
        title = {<Bread bread={[...bread, id?'编辑团队':'新增团队']} />}
        visible={visible}
        onOk={handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText={'确定'}
        cancelText={'取消'}
        width={600}
      >
        <Form
          {...layout}
          form={form}
          name="form_in_modal"
          initialValues={{ modifier: 'public' }}
        >
        <Row>
          <Col span={24}>
            <Form.Item 
              name="teamName" 
              label="团队名称"
              rules={[{ required: true, message: '请填写团队名称' }]}
            >
              <Input />
            </Form.Item>
          </Col>         
        </Row>
      </Form>
    </Modal>
  );
}