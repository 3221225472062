import { createRoutes } from '@/utils/core';
import BasicLayout from '@/layouts/BasicLayout';
import UserLayout from '@/layouts/UserLayout';
import NotFound from './Pages/404';

import Login from './Login';
import Register from './Register';
import Identify from './Identify';
import Home from './Home';
import Factory from './Factory';
import Project from './Project';
import Team from './Team';
import System from './System';
import Tpl from './Tpl';
import Sheet from './Sheet';
import Algorithm from './Algorithm';
import Monitor from './Monitor';
/**
 * 主路由配置
 * 
 * path 路由地址
 * component 组件
 * indexRoute 默认显示路由
 * childRoutes 所有子路由
 * NotFound 路由要放到最下面，当所有路由当没匹配到时会进入这个页面
 */
const routesConfig = app => [
  {
    path: '/sign',
    title: '登录',
    indexRoute: '/sign/login',
    component: UserLayout,
    childRoutes: [
      Login(app),
      Identify(app),
      Register(app),
      NotFound()
    ]
  },
  {
    path: '/',
    title: '系统中心',
    component: BasicLayout,
    indexRoute: '/home',
    childRoutes: [
      Home(app),
      Project(app),
      Factory(app),
      Team(app),
      System(app),
      Tpl(app),
      Sheet(app),
      Algorithm(app),
      Monitor(app),
      NotFound()
    ]
  }
];

export default app => createRoutes(app, routesConfig);
