import { routerRedux } from 'dva';
import $$ from 'cmn-utils';
import modelEnhance from '@/utils/modelEnhance';
// import { delete } from 'fetch-mock';

export default modelEnhance({
  namespace: 'global',

  state: {
    menu: [],
    flatMenu: [],
    userInfo:{},
    domain: {},
    showTeam: false, //是否显示 team
    teamList: [], // 我的团队列表
    currentTeamCode: 0, // 当前团队code
  },

  effects: {
    *reset({ payload }, { call, put }) {
      yield put({
        type: 'resetSuccess',
        payload: {
          showTeam: false,
          teamList: [],
          currentTeamCode: 0,
        },
      });
    },
    *getMenu({ payload }, { call, put }) {
      const { status, data } = yield call(getMenu, payload);
      if (status) {
        const loopMenu = (menu, pitem = {}) => {
          menu.forEach(item => {
            if (pitem.path) {
              item.parentPath = pitem.parentPath ? pitem.parentPath.concat(pitem.path) : [pitem.path];
            }
            if (item.children && item.children.length) {
              loopMenu(item.children, item);
            }
          });
        }
        loopMenu(data);
        
        yield put({
          type: 'getMenuSuccess',
          payload: data,
        });
      }
    },
    *getDict({ payload }, { call, put }) {
      const { status, data } = yield call(getDict, payload);
      let domain = {}
      data.map(item=>{
        domain[item.dictValue] = item.dictLabel
      })

      if (status) {
        yield put({
          type: 'getDictSuccess',
          payload: { domain },
        });
      }
    },
    *getUserInfo({ payload }, { call, put }) {
      const { success, data } = yield call(getUserInfo, payload);
      // console.log('user info:',data)
      if(success && data){
        if(data.userInfo){
          if(data.userInfo.status == '1'){
            yield put(routerRedux.replace('/sign/identify'));
            return;
          }
          
          if(data.groupInfos){
            for(let i=0; i<data.groupInfos.length; i++){
              let item = data.groupInfos[i];
              if(item.code && item.code == 'default_register'){
                yield put({ 
                  type: 'showTeamSuccess',
                  payload:{
                    showTeam: true
                  }
                });
                yield put({ 
                  type: 'getTeamList',
                  payload:{}
                });
                break;
              }
            }            
          }
        }
        
        yield put({
          type: 'getUserInfoSuccess',
          payload: data,
        });
      }
    },
    *getTeamList({ payload }, { call, put }) {
      const { success, data } = yield call(getTeamList, {});
      if(success && data){
        let currentTeamCode = 0;
        if(data.length) currentTeamCode = data[0].teamCode;
        yield put({
          type: 'getTeamListSuccess',
          payload: {
            currentTeamCode,
            teamList: data
          },
        });
      }
    },
    *changeCurrentTeam({ payload }, { call, put }) {
      let currentTeamCode = payload.teamCode;
      yield put({
        type: 'getTeamListSuccess',
        payload: {
          currentTeamCode,
        },
      });
    },
  },

  reducers: {
    resetSuccess(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    getMenuSuccess(state, { payload }) {
      return {
        ...state,
        menu: payload,
        flatMenu: getFlatMenu(payload),
      };
    },
    getDictSuccess(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    getUserInfoSuccess(state, { payload }) {    
      let { menus, permissions } = payload;
      menus.forEach(item => {
        if(!item.icon) item.icon = 'DesktopOutlined';
        if(item.children){
          item.children.forEach(v=>{
            if(!v.icon) v.icon = null;
            // if(v.path == '/project/my'){
            //   v.path = '/project/my/index';
            // }
          });
        }
      });
      menus.unshift({
        name: '首页',
        icon: 'HomeOutlined',
        path: '/home',
      });
      
      menus = [ ...menus];
      return {
        ...state,
        menu: menus,
        permissions,
        flatMenu: getFlatMenu(menus),
        userInfo: payload.userInfo,
      };
    },
    showTeamSuccess(state, { payload }){
      return {
        ...state,
        ...payload,
      };
    },
    getTeamListSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export function getFlatMenu(menus) {
  let menu = [];
  menus.forEach(item => {
    if (item.children) {
      menu = menu.concat(getFlatMenu(item.children));
    }
    menu.push(item);
  });
  return menu;
}

export async function getMenu(payload) {
  return $$.post('/user/menu', payload);
}
export async function getUserInfo(payload) {
  return $$.get('/platform/api/v1/current-user-info', payload);
}
export async function getTeamList (){
  return $$.get('/platform/api/v1/teams/my');
}
export async function getDict (){
  return $$.get('/platform/api/v1/dict-data/list',{
    dictType: 'domain'
  });
}