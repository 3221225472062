import {dynamicWrapper, createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import Admin from './routers/Admin';
const routesConfig = (app) => ({
  path: '/factory',
  title: '工厂',
  component: Routers,
  exact: false,
  indexRoute: '/factory/list',
  childRoutes: [
    Admin(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);