import {dynamicWrapper, createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import Data from './routers/Data';
import Type from './routers/Type';

const routesConfig = (app) => ({
  path: '/system/dict',
  title: '字典管理',
  component: Routers,
  exact: false,
  indexRoute: '/system/dict/data',
  childRoutes: [
    Data(app),
    Type(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
