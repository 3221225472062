import {dynamicWrapper, createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import Gather from './routers/Gather';

const routesConfig = (app) => ({
  path: '/monitor',
  title: '监控管理',
  component: Routers,
  exact: false,
  indexRoute: '/monitor/gather',
  childRoutes: [
    Gather(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
