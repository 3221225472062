import React, { useRef, useState } from 'react'
import { Modal, Table, Spin, Alert, Button, Popconfirm, message  } from 'antd'
import $$ from 'cmn-utils';

import TeamForm from './teamForm';
import TeamUser from './teamUser';
import TeamInvite from './teamInvite';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

export default function ({ permissions, refs, list, getList }) {

  refs.current = {
    show: () => {
      setVisible(true)
    },
    close: ()=>{
      setVisible(false)
    }
  }

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dt, setDt] = useState({});

  const [link, setLink] = useState('');

  const formRef = useRef();
  const userRef = useRef();
  const inviteRef = useRef();

  const handleCancel = ()=>{
    setVisible(false)
  }

  const handler = {
    add:()=>{
      formRef.current.add();
    },
    addOk:()=>{
      getList();
      formRef.current.close();
    },
    edit:(param)=>{
      formRef.current.edit(param);
    },
    user:(param)=>{
      userRef.current.show(param);
    },
    invite:(param)=>{
      inviteRef.current.show(param);
    },
    filter:{
      status:(value)=>{
        let text = '';
        switch(value){
          case '1':
            text = '申请中';
            break;
          case '2':
            text = '已加入';
            break;
          case '3':
            text = '已拒绝';
            break;
        }
        return text;
      }
    },
  }


  let columns = [
    {
        title: '团队名称',
        dataIndex: 'teamName',
        key: 'teamName',
    },
    {
        title: '操作',
        render: (text, record) => (
            <>
            {permissions && -1 !== permissions.findIndex(v=>v==='system:team:update') &&
            <Button size="small" type="link" onClick={()=>handler.edit(record)}>编辑</Button>
            }
            {permissions &&  -1 !== permissions.findIndex(v=>v==='system:team:create') &&
            <Button size="small" type="link" onClick={()=>handler.user(record)}>队员</Button>
            }
            {permissions &&  -1 !== permissions.findIndex(v=>v==='system:team:create') &&
            <Button size="small" type="link" onClick={()=>handler.invite(record)}>邀请队员</Button>
            }
            </>
        ),
    }
  ];

  return (
    <>
    <Modal
        title={'团队管理'}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        width={800}
      >
        <Spin spinning={loading}>
          {permissions &&  -1 !== permissions.findIndex(v=>v==='system:team:create') &&
          <Button type="primary" onClick={handler.add}>新增团队</Button>
          }
          <Table
            style={{
              marginTop:15
            }}
            rowKey="teamCode"
            columns={columns}
            dataSource={list}
            bordered
            pagination={false}
          />
        </Spin>
        {link && 
        <Alert style={{marginTop:20}} message={"邀请链接："+link} type="info" />
        }
    </Modal>
    <TeamForm refs={formRef} handleOk={handler.addOk} bread={['团队管理']} />
    <TeamUser refs={userRef} bread={['团队管理']}/>
    <TeamInvite refs={inviteRef} bread={['团队管理']} />
    </>
  );
}
